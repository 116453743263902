<script>
import UserAccountsSgm from '@/components/UserAccountsSgm.vue'
import UserAccountsIntegration from '@/components/UserAccountsIntegration.vue'

export default {
  name: 'ContasDeUsuario',
  components: {
    UserAccountsSgm,
    UserAccountsIntegration,
  },
  data() {
    return {
      activeTab: '#nav-sgm-accounts',
    }
  },
  methods: {
    setTab(name) {
      this.activeTab = name;
    },
  },

  created() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash;
    }
  }
}
</script>

<template>
  <div class="contas-de-usuario">
    <h1 class="titulo-card fs-4">Contas de usuário</h1>
    <div class="form-group card-principal">

      <nav>
        <div class="nav nav-tabs active" id="nav-tab" role="tablist">
          <a class="nav-link" :class="{'active': activeTab === '#nav-sgm-accounts'}" @click="setTab('#nav-sgm-accounts')" id="nav-sgm-accounts-tab" href="#nav-sgm-accounts" role="tab" aria-controls="nav-sgm-accounts" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'users']" fixed-width class="me-1"/>
            Usuários
          </a>
          <a class="nav-link" :class="{'active': activeTab === '#nav-integrations'}" @click="setTab('#nav-integrations')" id="nav-integrations-tab" href="#nav-integrations" role="tab" aria-controls="nav-integrations" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'plug']" fixed-width class="me-1"/>
            Integrações
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- sgm-accounts's -->
        <div class="tab-pane fade show" :class="{'active': activeTab === '#nav-sgm-accounts'}" id="nav-sgm-accounts" role="tabpanel" aria-labelledby="nav-sgm-accounts-tab">
          <UserAccountsSgm/>
        </div>
        <!-- sgm-accounts's -->

        <!-- integrations's -->
        <div class="tab-pane fade show" :class="{'active': activeTab === '#nav-integrations'}" id="nav-integrations" role="tabpanel" aria-labelledby="nav-integrations-tab">
          <UserAccountsIntegration/>
        </div>
        <!-- integrations's -->
      </div>
    </div>
  </div>
</template>
