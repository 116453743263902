<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "UserAccountsIntegration",
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaUsuarios: [],
      listaIPPools: [],
      listaGruposHelpdesk: [],
      modalUsuario: {
        data: {
          id: undefined,
          enabled: true,
          integrationType: 'sms_net',
          name: '',
          loginType: 'token',
          token: uuidv4(),
        },
      },
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/integration-users?${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaUsuarios = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.resetModalUsuario();
      this.abreModalCadastroUsuario();
    },

    async handleEditaUsuario(usuario) {
      this.resetModalUsuario();

      this.modalUsuario.data = clone(usuario);

      this.abreModalCadastroUsuario();
    },

    cssUser(user) {
      if (user.enabled) {
        return 'text-info';
      }

      return 'text-muted';
    },

    carregaGrupos() {
      api.get(`/ticket-groups?paginate.cancel&orderBy["order"]=asc`).then(res => {
        this.listaGruposHelpdesk = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaGrupos();
        }, 1000);
      });
    },

    translateIntegrationType(value) {
      switch (value) {
        case 'sms_net':
          return "SMS Net";

        default:
          return '';
      }
    },

    //MODAL EDIT
    abreModalCadastroUsuario() {
      document.getElementById('btn-modal-edita-usuario-integracao-abre').click();
    },

    resetModalUsuario() {
      this.modalUsuario.data.id = undefined;
      this.modalUsuario.data.enabled = true;
      this.modalUsuario.data.integrationType = 'sms_net';
      this.modalUsuario.data.name = '';
      this.modalUsuario.data.token = uuidv4();
    },

    podeCadastrarUsuario() {
      switch (true) {
        case (!this.modalUsuario.data.integrationType):
        case (this.modalUsuario.data.name.length < 3):
        case (this.modalUsuario.data.token.length < 1):
          return false;

        default:
          return true;
      }
    },

    podeSalvarUsuario() {
      switch (true) {
        case (!this.modalUsuario.data.integrationType):
        case (this.modalUsuario.data.name.length < 3):
        case (this.modalUsuario.data.token.length < 1):
          return false;

        default:
          return true;
      }
    },

    handleRemoveUsuario() {
      dialogo.confirmacao(`Confirma remover o usuário <b>${this.modalUsuario.data.name}</b>?`).then(() => {
        loading(true);

        api.delete(`/integration-users/${this.modalUsuario.data.id}`).then(() => {
          loading(false);

          document.getElementById('btn-modal-edita-usuario-integracao-fecha').click();
          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handlemodalUsuarioCadastrar() {
      const sendData = clone(this.modalUsuario.data);

      loading(true);
      api.post('/integration-users', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-usuario-integracao-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handlemodalUsuarioSalvar() {
      const sendData = clone(this.modalUsuario.data);

      loading(true);
      api.put(`/integration-users`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-usuario-integracao-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    generateNewToken() {
      this.modalUsuario.data.token = uuidv4();
    },
    //MODAL EDIT
  },
  created() {
    this.handlePesquisa();
    this.carregaGrupos();
  },
};
</script>


<template>
  <div class="user-accont-integrations">
    <h5 class="titulo-card">Integrações</h5>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>
      </div>
      <div class="d-flex">
        <!-- mobile -->
        <table class="table table-sm-pc table-striped table-hover d-sm-none">
          <thead>
            <tr>
              <th>Dados</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in listaUsuarios" :key="user.id" :class="cssUser(user)">
              <td scope="row">
                [{{translateIntegrationType(user.integrationType) || '-'}}]
                <strong>{{user.name}}</strong>
                <br>
                {{user.token}}
              </td>
              <td class="text-end">
                <button type="button" v-on:click="handleEditaUsuario(user)" class="btn btn-primary btn-sm me-1">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                </button>
              </td>

            </tr>
          </tbody>
        </table>
        <!-- mobile -->

        <!-- desktop -->
        <table class="table table-sm-pc table-striped table-hover d-none d-sm-table">
          <thead>
            <tr>
              <th>Identificação</th>
              <th>Integração</th>
              <th>Token</th>
              <th width="110"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in listaUsuarios" :key="user.id" :class="cssUser(user)">
              <td scope="row">{{user.name}}</td>
              <td>{{translateIntegrationType(user.integrationType) || '-'}}</td>
              <td>{{user.token}}</td>
              <td class="text-end">
                <button type="button" v-on:click="handleEditaUsuario(user)" class="btn btn-primary btn-sm me-1">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                </button>
              </td>

            </tr>
          </tbody>
        </table>
        <!-- desktop -->
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

      <!-- modal-edita-usuario-integracao -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-usuario-integracao" id="btn-modal-edita-usuario-integracao-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-edita-usuario-integracao" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!modalUsuario.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Cadastrar novo usuário
              </h5>
              <h5 v-if="!!modalUsuario.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editando usuário
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <button type="button" v-on:click="handleRemoveUsuario()" class="btn btn-outline-danger btn-sm float-end">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width/> Excluir
                  </button>
                </div>
                <div class="col-12">
                  <div class="form-check form-switch">
                    <input v-model="modalUsuario.data.enabled" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Ativo</label>
                  </div>
                </div>
                <div class="col-12">
                  <label class="required">Integração</label>
                  <input v-if="(modalUsuario.data.id)" type="text" disabled :value="translateIntegrationType(modalUsuario.data.integrationType)" class="form-control">
                  <select v-model="modalUsuario.data.integrationType" v-if="(!modalUsuario.data.id)" class="form-select">
                    <option :value="undefined" disabled>Selecione</option>
                    <option :value="'sms_net'">{{ translateIntegrationType('sms_net') }}</option>
                  </select>
                </div>
                <div class="col-12">
                  <label class="required">Identificação</label>
                  <input type="text" v-model="modalUsuario.data.name" class="form-control">
                </div>
                <div class="col-12">
                  <label class="required">Token</label>
                  <div class="input-group">
                    <input type="text" v-model="modalUsuario.data.token" disabled class="form-control">
                    <button @click="generateNewToken()" type="button" class="btn btn-outline-secondary">
                      <font-awesome-icon :icon="['fas', 'sync']" fixed-width/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-usuario-integracao-fecha">Fechar</button>
              <button type="button"
                  v-if="!modalUsuario.data.id"
                  v-on:click="handlemodalUsuarioCadastrar()"
                  :disabled="!podeCadastrarUsuario()"
                  class="btn btn-success">
                Cadastrar
              </button>
              <button type="button"
                  v-if="!!modalUsuario.data.id"
                  v-on:click="handlemodalUsuarioSalvar()"
                  :disabled="!podeSalvarUsuario()"
                  class="btn btn-success">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-usuario-integracao -->
  </div>
</template>

