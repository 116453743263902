<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: "UserAccountsSgm",
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaUsuarios: [],
      listaIPPools: [],
      listaGruposHelpdesk: [],
      modalUsuario: {
        showPwd: false,
        data: {
          id: undefined,
          enabled: true,
          name: '',
          email: '',
          login: '',
          password: '',
          helpdeskConfig: {
            groupId: '00000000-0000-0000-0000-000000000000',
          },
        },
      },
      modalTrocaSenha: {
        usuario: undefined,
        data: {
          id: undefined,
          password: '',
          passwordConfirm: '',
        },
      }
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/users?${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaUsuarios = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.resetModalUsuario();
      this.abreModalCadastroUsuario();
    },

    async handleEditaUsuario(usuario) {
      this.resetModalUsuario();

      this.modalUsuario.data = clone(usuario);

      this.abreModalCadastroUsuario();
    },

    handleRemoveUsuario(usuario) {
      dialogo.confirmacao(`Confirma remover o usuário <b>${usuario.name}</b>?`).then(() => {
        loading(true);

        api.delete(`/users/${usuario.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    cssUser(user) {
      if (user.enabled) {
        return 'text-info';
      }

      return 'text-muted';
    },

    carregaGrupos() {
      api.get(`/ticket-groups?paginate.cancel&orderBy["order"]=asc`).then(res => {
        this.listaGruposHelpdesk = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaGrupos();
        }, 1000);
      });
    },

    //MODAL EDIT
    abreModalCadastroUsuario() {
      document.getElementById('btn-modal-edita-usuario-abre').click();
    },

    resetModalUsuario() {
      this.modalUsuario.showPwd = false;
      this.modalUsuario.data.id = undefined;
      this.modalUsuario.data.enabled = true;
      this.modalUsuario.data.name = '';
      this.modalUsuario.data.email = '';
      this.modalUsuario.data.login = '';
      this.modalUsuario.data.password = '';
      this.modalUsuario.data.helpdeskConfig.groupId = '00000000-0000-0000-0000-000000000000';
    },

    podeCadastrarUsuario() {
      switch (true) {
        case (this.modalUsuario.data.name.length < 3):
        case (this.modalUsuario.data.email.length < 7):
        case (this.modalUsuario.data.login.length < 3):
        case (this.modalUsuario.data.password.length < 1):
          return false;

        default:
          return true;
      }
    },

    podeSalvarUsuario() {
      switch (true) {
        case (this.modalUsuario.data.name.length < 3):
        case (this.modalUsuario.data.email.length < 7):
        case (this.modalUsuario.data.login.length < 3):
          return false;

        default:
          return true;
      }
    },

    handlemodalUsuarioCadastrar() {
      const sendData = clone(this.modalUsuario.data);

      loading(true);
      api.post('/users', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-usuario-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handlemodalUsuarioSalvar() {
      const sendData = clone(this.modalUsuario.data);

      loading(true);
      api.put(`/users`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-usuario-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    //MODAL EDIT


    //MODAL TROCA SENHA
    modalTrocaSenhaHandleAbre(usuario) {
      this.modalTrocaSenhaReset();

      this.modalTrocaSenha.usuario = clone(usuario);
      this.modalTrocaSenha.data.id = usuario.id;

      this.modalTrocaSenhaAbre();
    },

    modalTrocaSenhaReset() {
      this.modalTrocaSenha.usuario = undefined;
      this.modalTrocaSenha.data.id = undefined;
      this.modalTrocaSenha.data.password = '';
      this.modalTrocaSenha.data.passwordConfirm = '';
    },

    modalTrocaSenhaAbre() {
      document.getElementById('btn-modal-troca-senha-usuario-abre').click();
    },

    modalTrocaSenhaPodeSalvar() {
      switch (true) {
        case (this.modalTrocaSenha.data.password.length < 1):
        case (this.modalTrocaSenha.data.password !== this.modalTrocaSenha.data.passwordConfirm):
          return false;

        default:
          return true;
      }
    },

    modalTrocaSenhaSalvarHandle() {
      const sendData = clone(this.modalTrocaSenha.data);

      loading(true);
      api.post(`/users/${sendData.id}/password`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-troca-senha-usuario-fecha').click();
        this.handlePesquisa();
        dialogo.alerta('A nova senha foi definida com sucesso.');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    //MODAL TROCA SENHA
  },
  created() {
    this.handlePesquisa();
    this.carregaGrupos();
  },
};
</script>


<template>
  <div class="user-accont-sgm">
    <h5 class="titulo-card">Usuários do SGM</h5>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Identificação</th>
              <th>Login</th>
              <th width="110"></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="user in listaUsuarios" :key="user.id" :class="cssUser(user)">
                <td scope="row">{{user.name}}</td>
                <td>{{user.login}}</td>
                <td class="text-end">
                  <button type="button" v-on:click="modalTrocaSenhaHandleAbre(user)" class="btn btn-outline-secondary btn-sm me-1">
                    <font-awesome-icon :icon="['fas', 'key']" fixed-width/>
                  </button>
                  <button type="button" v-on:click="handleEditaUsuario(user)" class="btn btn-primary btn-sm me-1">
                    <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                  </button>
                  <button type="button" v-on:click="handleRemoveUsuario(user)" class="btn btn-danger btn-sm">
                    <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                  </button>
                </td>

              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

      <!-- modal-edita-usuario -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-usuario" id="btn-modal-edita-usuario-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-edita-usuario" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="!modalUsuario.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Cadastrar novo usuário
              </h5>
              <h5 v-if="!!modalUsuario.data.id" class="modal-title">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editando usuário
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-switch">
                    <input v-model="modalUsuario.data.enabled" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Ativo</label>
                  </div>
                </div>
                <div class="col-12">
                  <label class="required">Identificação</label>
                  <input type="text" v-model="modalUsuario.data.name" class="form-control">
                </div>
                <div class="col-12">
                  <label class="required">E-mail</label>
                  <input type="text" v-model="modalUsuario.data.email" class="form-control">
                </div>
                <div class="col-12">
                  <label class="required">Login</label>
                  <input type="text" v-model="modalUsuario.data.login" class="form-control">
                </div>
                <div class="col-12">
                  <label class="required">Grupo Helpdesk</label>
                  <select v-model="modalUsuario.data.helpdeskConfig.groupId" class="form-select">
                    <option :value="'00000000-0000-0000-0000-000000000000'">Nenhum</option>
                    <option v-for="grupo in listaGruposHelpdesk" :key="grupo.id" :value="grupo.id">{{grupo.description}}</option>
                  </select>
                </div>
              </div>
              <div v-show="!modalUsuario.data.id" class="row">
                <div class="col-12">
                  <label class="required">Senha</label>
                  <div class="input-group">
                    <input v-if="!modalUsuario.showPwd" type="password" v-model="modalUsuario.data.password" class="form-control">
                    <input v-if="modalUsuario.showPwd" type="text" v-model="modalUsuario.data.password" class="form-control">
                    <button @click="modalUsuario.showPwd = !modalUsuario.showPwd" class="btn btn-outline-secondary">
                      <font-awesome-icon v-if="modalUsuario.showPwd" :icon="['fas', 'eye-slash']" fixed-width/>
                      <font-awesome-icon v-if="!modalUsuario.showPwd" :icon="['fas', 'eye']" fixed-width/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-usuario-fecha">Fechar</button>
              <button type="button"
                  v-if="!modalUsuario.data.id"
                  v-on:click="handlemodalUsuarioCadastrar()"
                  :disabled="!podeCadastrarUsuario()"
                  class="btn btn-success">
                Cadastrar
              </button>
              <button type="button"
                  v-if="!!modalUsuario.data.id"
                  v-on:click="handlemodalUsuarioSalvar()"
                  :disabled="!podeSalvarUsuario()"
                  class="btn btn-success">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-usuario -->

    <!-- modal-troca-senha-usuario -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-troca-senha-usuario" id="btn-modal-troca-senha-usuario-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-troca-senha-usuario" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'key']" fixed-width/>
              Trocar senha do usuário
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h6>
              <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
              {{modalTrocaSenha.usuario ? modalTrocaSenha.usuario.name : ''}}
            </h6>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label class="required">Senha</label>
                <input type="password" v-model="modalTrocaSenha.data.password" class="form-control">
              </div>
              <div class="col-12 col-sm-6">
                <label class="required">Confirmação</label>
                <input type="password" v-model="modalTrocaSenha.data.passwordConfirm" class="form-control">
                <p v-if="modalTrocaSenha.data.password !== modalTrocaSenha.data.passwordConfirm"
                    class="mb-0 text-danger small">
                  As senhas não conferem
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-troca-senha-usuario-fecha">Fechar</button>
            <button type="button"
                v-on:click="modalTrocaSenhaSalvarHandle()"
                :disabled="!modalTrocaSenhaPodeSalvar()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-troca-senha-usuario -->
  </div>
</template>

